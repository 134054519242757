var render = function () {
  var _vm$tournament, _vm$tournament2, _vm$tournament2$disci, _vm$tournament3, _vm$tournament4, _vm$tournament5, _vm$tournament6, _vm$tournament7, _vm$tournament8, _vm$tournament9, _vm$tournament10, _vm$tournament11, _vm$tournament12, _vm$tournament12$disc, _vm$tournament13, _vm$tournament14, _vm$tournament15;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "text-center w-100 ",
    attrs: {
      "title": (_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.name
    }
  }, [_c('b-card-text', [_c('b-row', [_c('b-col', [_vm._v("Game: " + _vm._s((_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : (_vm$tournament2$disci = _vm$tournament2.discipline) === null || _vm$tournament2$disci === void 0 ? void 0 : _vm$tournament2$disci.title))])], 1), _c('b-row', [_c('b-col', [_vm._v(" " + _vm._s(_vm.participantsJoined) + " of " + _vm._s(_vm.tournament.participants_number) + " " + _vm._s(_vm.participantType) + " joined ")])], 1), _c('b-row', [_c('b-col', [_vm._v("Start: " + _vm._s(_vm.startAt))])], 1), _c('b-row', [_c('b-col', [_vm._v(_vm._s(_vm.registerDaysLeft))])], 1), _c('b-row', [_c('b-col')], 1), _c('b-row', [_c('b-col', [_c('span', [_vm._v("Platform:")]), _vm._l(_vm.tournament.platforms, function (platform) {
    return _c('span', {
      key: platform.id
    }, [_vm._v(" " + _vm._s(platform.title) + " ")]);
  })], 2)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [((_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : _vm$tournament3.status_id) === _vm.TournamentStatusType.DRAFT ? _c('b-badge', {
    attrs: {
      "variant": "light-secondary"
    }
  }, [_vm._v(" Draft ")]) : _vm._e(), ((_vm$tournament4 = _vm.tournament) === null || _vm$tournament4 === void 0 ? void 0 : _vm$tournament4.status_id) === _vm.TournamentStatusType.PUBLISHED ? _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Signup phase ")]) : _vm._e(), ((_vm$tournament5 = _vm.tournament) === null || _vm$tournament5 === void 0 ? void 0 : _vm$tournament5.status_id) === _vm.TournamentStatusType.FINISHED ? _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Ended ")]) : _vm._e(), ((_vm$tournament6 = _vm.tournament) === null || _vm$tournament6 === void 0 ? void 0 : _vm$tournament6.status_id) === _vm.TournamentStatusType.CLOSED ? _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Canceled ")]) : _vm._e(), ((_vm$tournament7 = _vm.tournament) === null || _vm$tournament7 === void 0 ? void 0 : _vm$tournament7.status_id) === _vm.TournamentStatusType.IN_PROGRESS ? _c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" Currently running ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "d-flex justify-content-evenly",
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleOpenTournament
    }
  }, [_vm._v(" Open ")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "size": "sm",
      "variant": "primary",
      "disabled": ((_vm$tournament8 = _vm.tournament) === null || _vm$tournament8 === void 0 ? void 0 : _vm$tournament8.status_id) === _vm.TournamentStatusType.FINISHED
    },
    on: {
      "click": _vm.redirectToEdit
    }
  }, [_vm._v(" Edit ")])], 1)], 1)], 1), _c('b-card-footer', [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-between",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Tournament admins")]), _c('span', [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary",
      "disabled": ((_vm$tournament9 = _vm.tournament) === null || _vm$tournament9 === void 0 ? void 0 : _vm$tournament9.status_id) === _vm.TournamentStatusType.FINISHED
    },
    on: {
      "click": _vm.handleEditTournamentStaff
    }
  }, [_vm._v(" Edit ")])], 1)]), _c('b-col', {
    staticClass: "d-flex justify-content-start mt-1",
    attrs: {
      "sm": "12"
    }
  }, [_vm._l((_vm$tournament10 = _vm.tournament) === null || _vm$tournament10 === void 0 ? void 0 : _vm$tournament10.staff, function (staff) {
    var _staff$user;
    return _c('b-badge', {
      key: staff.id,
      staticClass: "mr-1",
      attrs: {
        "variant": "light-secondary"
      }
    }, [_vm._v(" " + _vm._s(staff === null || staff === void 0 ? void 0 : (_staff$user = staff.user) === null || _staff$user === void 0 ? void 0 : _staff$user.name) + " ")]);
  }), !((_vm$tournament11 = _vm.tournament) !== null && _vm$tournament11 !== void 0 && _vm$tournament11.staff) || _vm.tournament.staff.length === 0 ? _c('span', [_c('i', [_vm._v("No admins")])]) : _vm._e()], 2)], 1), _c('hr'), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.getDisciplineImage((_vm$tournament12 = _vm.tournament) === null || _vm$tournament12 === void 0 ? void 0 : (_vm$tournament12$disc = _vm$tournament12.discipline) === null || _vm$tournament12$disc === void 0 ? void 0 : _vm$tournament12$disc.id),
      "size": "40",
      "square": ""
    }
  })], 1), _c('b-col', [_c('b-row', [_c('b-col', [!((_vm$tournament13 = _vm.tournament) !== null && _vm$tournament13 !== void 0 && _vm$tournament13.entry_fee) ? _c('span', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" NO ENTRY FREE ")])], 1) : _c('span', [_vm._v("Entry fee: " + _vm._s((_vm$tournament14 = _vm.tournament) === null || _vm$tournament14 === void 0 ? void 0 : _vm$tournament14.entry_fee) + " €")])])], 1), (_vm$tournament15 = _vm.tournament) !== null && _vm$tournament15 !== void 0 && _vm$tournament15.prizes ? _c('b-row', [_c('b-col', [_c('span', [_vm._v("tournament?.prizes")])])], 1) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }