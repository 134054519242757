var render = function () {
  var _vm$organizer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('edit-tournament-staff-modal', {
    attrs: {
      "organizer-members": (_vm$organizer = _vm.organizer) === null || _vm$organizer === void 0 ? void 0 : _vm$organizer.members,
      "tournament": _vm.currentTournament
    }
  }), _c('b-row', [_c('b-col', [_c('b-card-actions', {
    staticClass: "mb-1",
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('b-card-body', {
    staticClass: "pb-0 pt-0"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tournament name",
      "label-for": "searchQuery"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-1",
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Platforms",
      "label-for": "platforms"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "platforms",
      "options": _vm.platformsOptions,
      "reduce": function reduce(platform) {
        return platform.code;
      },
      "multiple": ""
    },
    model: {
      value: _vm.platformsFilter,
      callback: function callback($$v) {
        _vm.platformsFilter = $$v;
      },
      expression: "platformsFilter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "status",
      "options": _vm.statusOptions,
      "reduce": function reduce(status) {
        return status.code;
      }
    },
    model: {
      value: _vm.statusFilter,
      callback: function callback($$v) {
        _vm.statusFilter = $$v;
      },
      expression: "statusFilter"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'dashboard-tournaments-new'
        });
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon",
      "size": "16"
    }
  }), _vm._v(" Create new")], 1)], 1)], 1), _c('b-row', [_vm.isLoading ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-spinner')], 1) : _vm._e(), _vm._l(_vm.searchResults, function (tournament) {
    return _c('b-col', {
      key: tournament.id,
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "12"
      }
    }, [_c('organizer-tournament-card', {
      attrs: {
        "tournament": tournament
      },
      on: {
        "editTournamentStaffClick": _vm.showStaffEditModal
      }
    })], 1);
  }), !_vm.isLoading && (!_vm.searchResults || _vm.searchResults.length === 0) ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("There are no competitions")])])])], 1) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }