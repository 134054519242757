<template>
  <div>
    <!-- Tournament staff modal -->
    <edit-tournament-staff-modal
      :organizer-members="organizer?.members"
      :tournament="currentTournament"
    />

    <!-- Search filters -->
    <b-row>
      <b-col>
        <b-card-actions
          title="Filters"
          class="mb-1"
          action-collapse
        >
          <b-card-body class="pb-0 pt-0">
            <b-row>
              <b-col
                sm="12"
                md="3"
              >
                <b-form-group
                  label="Tournament name"
                  label-for="searchQuery"
                >
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </b-form-group>
              </b-col>

              <b-col
                sm="12"
                md="3"
              >
                <b-form-group
                  label="Platforms"
                  label-for="platforms"
                >
                  <v-select
                    id="platforms"
                    v-model="platformsFilter"
                    :options="platformsOptions"
                    :reduce="platform => platform.code"
                    multiple
                  />
                </b-form-group>
              </b-col>

              <b-col
                sm="12"
                md="3"
              >
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <v-select
                    id="status"
                    v-model="statusFilter"
                    :options="statusOptions"
                    :reduce="status => status.code"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col
        sm="12"
      >
        <b-button
          variant="primary"
          @click="$router.push({name: 'dashboard-tournaments-new'})"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          /> Create new</b-button>
      </b-col>
    </b-row>

    <!-- Search results -->
    <b-row>
      <b-col
        v-if="isLoading"
        cols="12"
      >
        <b-spinner />
      </b-col>

      <b-col
        v-for="(tournament) in searchResults"
        :key="tournament.id"
        lg="3"
        md="6"
        sm="12"
      >
        <organizer-tournament-card
          :tournament="tournament"
          @editTournamentStaffClick="showStaffEditModal"
        />
      </b-col>

      <b-col
        v-if="!isLoading && (!searchResults || searchResults.length === 0)"
        cols="12"
      >
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body">
            <span>There are no competitions</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BAlert, BButton,
  BCardBody,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _debounce from 'lodash.debounce'
import EditTournamentStaffModal from '@/views/dashboard/EditTournamentStaffModal.vue'
import OrganizerTournamentCard from '@/views/dashboard/tournament/OrganizerTournamentCard.vue'
import Platforms from '@/constants/Platforms'
import { BCardActions } from '@core/components/b-card-actions'

export default {
  components: {
    EditTournamentStaffModal,
    BAlert,
    BCardActions,
    BRow,
    BCol,
    BCardBody,
    BFormInput,
    BFormGroup,
    BSpinner,
    BButton,
    vSelect,
    OrganizerTournamentCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      searchQuery: null,
      searchResults: [],
      platformsFilter: null,
      statusFilter: null,
      currentTournament: {},
      organizerMembers: [],
      statusOptions: [
        { code: 1, label: 'Draft' },
        { code: 2, label: 'Published' },
        { code: 3, label: 'In Progress' },
        { code: 4, label: 'Finished' },
        { code: 5, label: 'Closed' },
      ],
    }
  },
  computed: {
    platformsOptions() {
      return Platforms.map(item => ({ label: item.title, code: Number(item.id) }))
    },
    organizer() {
      return this.$store.getters['organizer/getDashboardOrganizer']
    },
  },
  watch: {
    searchQuery() {
      this.fetchTournaments()
    },
    platformsFilter() {
      this.fetchTournaments()
    },
    statusFilter() {
      this.fetchTournaments()
    },
  },
  async created() {
    this.fetchTournaments = _debounce(this.fetchTournaments, 500)

    await this.fetchTournaments()
  },
  methods: {
    async fetchTournaments() {
      // eslint-disable-next-line no-invalid-this
      this.isLoading = true
      // eslint-disable-next-line no-invalid-this

      this.searchResults = []
      const where = {
        organizer_id: Number(this.$route.params.id),
        search_query: this.searchQuery || null,
        platform_filter: this.platformsFilter || null,
        status_filter: this.statusFilter || null,
      }

      const { data } = await this.$api.organizer.dashboardOrganizerTournaments(where)

      this.searchResults = data

      this.isLoading = false
    },
    async search() {
      this.loading = true

      this.loading = false
    },
    showStaffEditModal(tournament) {
      this.currentTournament = tournament
      this.$bvModal.show('edit-tournament-staff-modal')
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .demo-inline-spacing > * {
    margin-top: 0;
  }
</style>
