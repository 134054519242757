<template>

  <b-card
    :title="tournament?.name"
    class="text-center w-100 "
  >
    <b-card-text>
      <!-- Game -->
      <b-row>
        <b-col>Game: {{ tournament?.discipline?.title }}</b-col>
      </b-row>

      <!-- Teams -->
      <b-row>
        <b-col>
          {{ participantsJoined }} of {{ tournament.participants_number }}
          {{ participantType }} joined
        </b-col>
      </b-row>

      <!-- Start -->
      <b-row>
        <b-col>Start: {{ startAt }}</b-col>
      </b-row>

      <!-- Days to join -->
      <b-row>
        <b-col>{{ registerDaysLeft }}</b-col>
      </b-row>

      <!-- Match type -->
      <b-row>
        <b-col />
      </b-row>

      <!-- Platform -->
      <b-row>
        <b-col>
          <span>Platform:</span>
          <span
            v-for="platform in tournament.platforms"
            :key="platform.id"
          >
            {{ platform.title }}
          </span>
        </b-col>
      </b-row>

      <!-- Status -->
      <b-row class="mt-1">
        <b-col
          lg="6"
          sm="12"
        >
          <b-badge
            v-if="tournament?.status_id === TournamentStatusType.DRAFT"
            variant="light-secondary"
          >
            Draft
          </b-badge>

          <b-badge
            v-if="tournament?.status_id === TournamentStatusType.PUBLISHED"
            variant="light-success"
          >
            Signup phase
          </b-badge>

          <b-badge
            v-if="tournament?.status_id === TournamentStatusType.FINISHED"
            variant="light-primary"
          >
            Ended
          </b-badge>

          <b-badge
            v-if="tournament?.status_id === TournamentStatusType.CLOSED"
            variant="light-primary"
          >
            Canceled
          </b-badge>

          <b-badge
            v-if="tournament?.status_id === TournamentStatusType.IN_PROGRESS"
            variant="light-warning"
          >
            Currently running
          </b-badge>
        </b-col>

        <b-col
          lg="6"
          sm="12"
          class="d-flex justify-content-evenly"
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            @click="handleOpenTournament"
          >
            Open
          </b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            :disabled="tournament?.status_id === TournamentStatusType.FINISHED"
            @click="redirectToEdit"
          >
            Edit
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>

    <b-card-footer>
      <!-- Tournament admins -->
      <b-row>
        <b-col
          class="d-flex justify-content-between"
          cols="12"
        >
          <span class="font-weight-bold">Tournament admins</span>
          <span>
            <b-button
              size="sm"
              variant="primary"
              :disabled="tournament?.status_id === TournamentStatusType.FINISHED"
              @click="handleEditTournamentStaff"
            >
              Edit
            </b-button>
          </span>
        </b-col>
        <b-col
          class="d-flex justify-content-start mt-1"
          sm="12"
        >
          <b-badge
            v-for="staff in tournament?.staff"
            :key="staff.id"
            class="mr-1"
            variant="light-secondary"
          >
            {{ staff?.user?.name }}
          </b-badge>

          <span v-if="!tournament?.staff || tournament.staff.length === 0">
            <i>No admins</i>
          </span>
        </b-col>
      </b-row>

      <hr>
      <!-- Entry fee -->
      <b-row
        class="mt-1"
      >
        <b-col sm="3">

          <b-avatar
            :src="getDisciplineImage(tournament?.discipline?.id)"
            size="40"
            square
          />
        </b-col>

        <b-col>
          <b-row>
            <b-col>
              <span v-if="!tournament?.entry_fee">
                <b-badge
                  variant="light-success"
                >
                  NO ENTRY FREE
                </b-badge>
              </span>
              <span v-else>Entry fee: {{ tournament?.entry_fee }} €</span>
            </b-col>
          </b-row>
          <b-row v-if="tournament?.prizes">
            <b-col>
              <span>tournament?.prizes</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardFooter,
  BCardText,
  BCol,
  BRow,
} from 'bootstrap-vue'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import TournamentStatusType from '@/constants/TournamentStatusType'
import { getDisciplineImage } from '@/views/common'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardFooter,
    BAvatar,
    BBadge,
    BButton,
  },
  props: {
    tournament: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      prefix: this.routerPrefix,
    }
  },
  computed: {
    TournamentStatusType() {
      return TournamentStatusType
    },
    participantType() {
      if (
          this.tournament?.participant_type_id === TournamentParticipantType.player
      ) {
        return 'players'
      }
      return 'teams'
    },
    participantsJoined() {
      let joinedParticipants = 0

      if (this.tournament?.participants) {
        joinedParticipants = this.tournament?.participants.length
      }

      return joinedParticipants
    },
    startAt() {
      const startDate = new Date(this.tournament.start_at)

      return `${startDate.toLocaleDateString()} at ${startDate.toLocaleTimeString()}`
    },
    registerDaysLeft() {
      let daysToJoin = 0

      const startDate = new Date(this.tournament.start_at)
      const today = new Date()

      if (startDate >= today) {
        const daysBetween = (startDate.getTime() - today.getTime()) / (1000 * 3600 * 24)

        if (daysBetween > 0) {
          daysToJoin = Math.round(daysBetween)
        }
      }

      if (daysToJoin <= 0) {
        return null
      }

      return `${daysToJoin} days left to join`
    },

  },
  methods: {
    getDisciplineImage,
    handleOpenTournament() {
      this.$router.push({
        name: 'dashboard-tournament-overview',
        params: {
          id: this.$route.params.id,
          tournamentId: this.tournament.id,
        },
      })
    },
    showInfoClick() {},
    redirectToEdit() {
      this.$router.push({
        name: 'dashboard-tournaments-edit',
        params: {
          id: this.$route.params.id,
          tournamentId: this.tournament.id,
        },
      })
    },
    handleEditTournamentStaff() {
      this.$emit('editTournamentStaffClick', this.tournament)
    },
  },
}
</script>
