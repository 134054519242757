<template>
  <b-modal
    id="edit-tournament-staff-modal"
    no-close-on-backdrop
    ok-only
    ok-title="Close"
    :title="title"
    @hidden="resetModal"
  >
    <template #modal-footer="{ cancel }">
      <b-row class="w-100 mr-1 ml-1 justify-content-start">
        <dismissible-message-box
          :auto-dismiss="6000"
          :show="showMessageBox"
          :variant="messageboxType"
          class="p-1 w-100"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          {{ message }}
        </dismissible-message-box>
      </b-row>
      <b-row class="justify-content-between w-100 mr-1 ml-1">
        <spinning-button
          :is-loading="saving"
          type="submit"
          variant="primary"
          @click.native="handleStaffUpdate"
        >
          Save
        </spinning-button>
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Close
        </b-button>
      </b-row>
    </template>

    <b-container>
      <b-row class="d-flex justify-space-around mb-2">
        <b-col>
          <p class="text-justify">
            Pick staff you want to add to this tournament. Before you can add
            them you must invite them to your organization. You can always
            change staff later. Keep in mind that organizer have all power over
            tournament and staff has livechat access to tournament participants.
            You can change members status in homepage of your organization.
          </p>
        </b-col>
      </b-row>

      <h5>Staff:</h5>
      <b-row class="d-flex justify-space-around">
        <b-col sm="12">
          <b-badge
            v-for="member in organizerMembersOptions"
            :key="member.id"
            style="padding: 10px"
            :variant="
              isMemberSelectedStaff(member) ? 'primary' : 'light-secondary'
            "
            class="mr-1"
            @click="() => handleSelectUnselect(member)"
          >
            {{ member.user.name }}
          </b-badge>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BBadge, BButton, BCol, BContainer, BRow,
} from 'bootstrap-vue'
import MessageType from '@/constants/MessageType'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import SpinningButton from '@/components/SpinningButton.vue'

export default {
  components: {
    BButton,
    SpinningButton,
    DismissibleMessageBox,
    BContainer,
    BRow,
    BCol,
    BBadge,
  },
  props: {
    tournament: {
      type: Object,
      default: () => {
      },
    },
    organizerMembers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      MessageType,
      saving: false,
      showMessageBox: false,
      messageboxType: '',
      message: '',
      selectedStaff: this.tournament?.staff || [],
      organizerMembersOptions: this.organizerMembers || [],
      members: [],
      tournamentLocal: this.tournament || {},
    }
  },
  computed: {
    title() {
      return `Edit ${this.tournamentLocal?.name} staff`
    },
  },
  watch: {
    organizerMembers(value) {
      this.organizerMembersOptions = value || []
    },
    tournament(value) {
      this.tournamentLocal = value
      this.selectedStaff = value?.staff || []
    },
  },
  methods: {
    isMemberSelectedStaff(member) {
      return (
          this.selectedStaff?.findIndex(
            staffUser => staffUser.user.id === member.user.id,
          ) !== -1
      )
    },

    async handleSelectUnselect(member) {
      const staff = this.selectedStaff.findIndex(
        staffUser => staffUser.user.id === member.user.id,
      )

      if (staff === -1) {
        this.selectedStaff.push({
          id: null,
          tournament_id: this.tournamentLocal.id,
          user: member.user,
          user_id: member.user.id,
        })
      } else {
        this.selectedStaff.splice(staff, 1)
      }
    },
    async handleStaffUpdate() {
      const payload = {
        tournament_id: this.tournamentLocal.id,
        user_ids: this.selectedStaff.map(s => s.user.id) || [],
      }
      this.saving = true
      await this.$api.organizer.saveTournamentStaff(payload)
      this.saving = false

      this.$emit('tournamentStaffUpdated')

      this.$bvModal.hide('edit-tournament-staff-modal')
    },
    resetModal() {
      this.selectedStaff = []
      this.loading = false
    },
  },
}
</script>
