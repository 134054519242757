var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "edit-tournament-staff-modal",
      "no-close-on-backdrop": "",
      "ok-only": "",
      "ok-title": "Close",
      "title": _vm.title
    },
    on: {
      "hidden": _vm.resetModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-row', {
          staticClass: "w-100 mr-1 ml-1 justify-content-start"
        }, [_c('dismissible-message-box', {
          staticClass: "p-1 w-100",
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showMessageBox,
            "variant": _vm.messageboxType
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.message) + " ")], 1)], 1), _c('b-row', {
          staticClass: "justify-content-between w-100 mr-1 ml-1"
        }, [_c('spinning-button', {
          attrs: {
            "is-loading": _vm.saving,
            "type": "submit",
            "variant": "primary"
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.handleStaffUpdate($event);
            }
          }
        }, [_vm._v(" Save ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)];
      }
    }])
  }, [_c('b-container', [_c('b-row', {
    staticClass: "d-flex justify-space-around mb-2"
  }, [_c('b-col', [_c('p', {
    staticClass: "text-justify"
  }, [_vm._v(" Pick staff you want to add to this tournament. Before you can add them you must invite them to your organization. You can always change staff later. Keep in mind that organizer have all power over tournament and staff has livechat access to tournament participants. You can change members status in homepage of your organization. ")])])], 1), _c('h5', [_vm._v("Staff:")]), _c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, _vm._l(_vm.organizerMembersOptions, function (member) {
    return _c('b-badge', {
      key: member.id,
      staticClass: "mr-1",
      staticStyle: {
        "padding": "10px"
      },
      attrs: {
        "variant": _vm.isMemberSelectedStaff(member) ? 'primary' : 'light-secondary'
      },
      on: {
        "click": function click() {
          return _vm.handleSelectUnselect(member);
        }
      }
    }, [_vm._v(" " + _vm._s(member.user.name) + " ")]);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }